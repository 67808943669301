import styles from "./Footer.module.css";

import Gallery from "@/components/Gallery";
import Spacer from "@/components/Spacer";

function Footer() {
  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <div className={styles.footer}>
      <Spacer />
      <Gallery />
      <Spacer />
      <p className="has-text-grey-lighter has-text-weight-medium">
        {`©${getYear()} Fabio - Tous droits réservés - Johann P.`}
      </p>
      <br />
    </div>
  );
}

export default Footer;
