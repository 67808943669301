import styles from "./Title.module.css";

function Title({children, isWhite}: {children: React.ReactNode; isWhite?: boolean}) {
  return (
  <div className={styles.wrapper}>
    <h1 className={`title is-2 ${isWhite && 'has-text-white'}`}>{children}</h1>
  </div>
  )
}

export default Title;
