const data = {
  salades: [
    {
      name: "Salade verte",
      desc: "",
      price: 3.9,
    },
    {
      name: "Salade Comtoise",
      desc: "Salade, tomates, dès de Comté et jambon blanc",
      price: 8.1,
    },
    {
      name: "Salade Landaise",
      desc: "Salade, tomates, magret de canard ; gesiers",
      price: 9.9,
    },
    {
      name: "Salade au fromage de chèvre chaud sur croûtons",
      desc: "Salade, tomates, chèvre chaud, jambon",
      price: 9.1,
    },
    {
      name: "Croute aux Morilles",
      desc: "",
      price: 16.6,
    },
  ],
  viandes: [
    {
      name: "Faux filet / frites / salade",
      price: 17.8,
    },
    {
      name: "Andouillette sauce moutarde à l'ancienne",
      price: 18.9,
    },
    {
      name: "Suprême de poulet fermier aux morilles et vin jaune",
      price: 26.9,
    },
    {
      name: "Filet de boeuf grillé nature",
      price: 26.9,
    },
    {
      name: "Filet de boeuf aux morilles et vin jaune",
      price: 29.9,
    },
    {
      name: "Tartare de boeuf",
      price: 17.9,
    },
  ],
  plats: [
    {
      name: "Poisson Frais selon arrivage",
    },
    {
      name: "Tagliatelles Carbonara",
      price: 18.9,
    },
  ],
  pizzas: [
    {
      name: "Romana",
      desc: "Tomate, fromage, olives",
    },
    {
      name: "Marguerite",
      desc: "tomate, fromage,jambon,olives",
    },
    {
      name: "Royale",
      desc: "Tomate, fromage, jambon, champignons, olives",
    },
    {
      name: "Océane",
      desc: "Tomate, fromage, fruits de mer, olives",
    },
    {
      name: "Napolitaine",
      desc: "Tomate, fromage, anchois, olives",
    },
    {
      name: "4 Saisons",
      desc: "Tomate, fromage, champignons, oignons, asperges, artichaut, olives",
    },
    {
      name: "Sophia",
      desc: "Tomate, fromage, Mozzarella fraiche, tomate fraîche, basilic, olives",
    },
    {
      name: "Méridionale",
      desc: "Tomate, fromage, thon, oignons, crème, olives",
    },
    {
      name: "Campagnarde",
      desc: "Tomate, fromage, lardons, œuf, olives",
    },
    {
      name: "Alsacienne",
      desc: "Crème, fromage, lardons, ail, oignons, olives",
    },
    {
      name: "Orientale",
      desc: "Tomate, fromage, merguez, poivron, épices, olives",
    },
    {
      name: "Andalouse",
      desc: "Tomate, fromage, chorizo, poivrons, olives",
    },
    {
      name: "Dijonnaise",
      desc: "Crème, fromage, moutarde, andouillette, ignons, olives",
    },
    {
      name: "Trois fromages",
      desc: "Tomate, fromage, bleu, chèvre",
    },
    {
      name: "Paysanne",
      desc: "Tomate, fromage, lardons, oignons, olives",
    },
    {
      name: "Charolaise",
      desc: "Tomate, fromage, viande hachée, auf, olives",
    },
    {
      name: "Exotique",
      desc: "Tomate, fromage, jambon cru, ananas, olives",
    },
    {
      name: "Fabio",
      desc: "Tomate, fromage, poulet au curry, bleu, olives",
    },
    {
      name: "Chevrotin",
      desc: "Tomate, fromage, jambon cru, chèvre, olives",
    },
    {
      name: "Espagnole",
      desc: "Tomate, fromage, chorizo, merguez, olives",
    },
    {
      name: "Kebab",
      desc: "Tomate, fromage, moutarde, oignons, viande de kebab, crème, olives",
    },
    {
      name: "Tradition",
      desc: "Tomate, fromage, Morteau, cancoillotte, pomme de terre",
    },
    {
      name: "Savoyarde",
      desc: "Tomate, fromage, jambon, raclette, pomme de terre, olives",
    },
    {
      name: "Morbiflette",
      desc: "Tomate, fromage, jambon cru, pomme de terre, Morbier, olives",
    },
    {
      name: "Gorgonzola",
      desc: "Tomate, fromage, rosette, Gorgonzola, olives",
    },
    {
      name: "Tartiflette",
      desc: "Tomate, fromage, lardons, pomme de terre, reblochon, persillade, olives",
    },
    {
      name: "L'escargot",
      desc: "Crème, fromage, escargots, persillade, olives",
    },
    {
      name: "Mont d'or (du 10/09 au 10/05)",
      desc: "Tomate, fromage, jambon, pomme de terre, Mont d'or, olives",
    },
    {
      name: "Norvégienne",
      desc: "Tomate, fromage, saumon, épinards, crème, olives",
    },
    {
      name: "Morilles",
      desc: "Crème, morilles, poulet, fromage, olives",
    },
  ],
  desserts: [
    {
      name: "Assiette de fromage",
    },
    {
      name: "Fromage blanc",
    },
    {
      name: "Tarte tatin",
    },
    {
      name: "Assiette gourmande",
    },
    {
      name: "Financier aux framboises",
    },
    {
      name: "Crème brûlée au Macvin",
    },
    {
      name: "Profiteroles",
    },
    {
      name: "Moelleux au chocolat",
    },
    {
      name: "Dame blanche",
      desc: "3 boules de glace vanille, sauce chocolat, amandes grillées, chantilly, biscuit",
    },
    {
      name: "Poire Belle Hélène",
      desc: "2 boules de glace vanille, 1/2 poire au sirop, sauce chocolat, chantilly, biscuit",
    },
    {
      name: "Chocolat liégeois",
      desc: "2 boules de glace chocolat et 1 vanille, sauce chocolat, chantilly, biscuit",
    },
    {
      name: "Café liégeois",
      desc: "2 boules de glace café et 1 vanille, sauce café, chantilly, biscuit",
    },
    {
      name: "Colonel",
      desc: "2 boules de sorbet citron, vodka, biscuit",
    },
    {
      name: "Coupe Fougerolles",
      desc: "2 boules de glace vanille, griottines de Fougerolles, biscuit",
    },
    {
      name: "Williamine",
      desc: "2 boules de sorbet poire, digestif de poire, biscuit",
    },
    {
      name: "Coupe 27",
      desc: "2 Boules de glace menthe, Get 27, biscuit",
    },
    {
      name: "Coupe Jurassienne",
      desc: "2 boules noix, Macvin, biscuit",
    },
    {
      name: "Coupe de glace au choix",
      desc: "De 1 à 3 boules parmi nos 17 parfums listés ci-dessous",
    },
  ],
};

export default data;
