import styles from "./Location.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPhone,
  faLocationArrow,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import Background from "@/components/Background";
import Title from "@/components/Title";
import Spacer from "@/components/Spacer";

function Location() {
  const InfoDiv = () => {
    return (
      <div className={styles.infos}>
        <div className={styles.header}>
          <div className={styles.iconSep}>
            <FontAwesomeIcon icon={faHome} className={styles.icon} />
            <div className={styles.sep} />
          </div>
          <div className={styles.content}>
            <p
              className="subtitle is-4 has-text-white"
              style={{ marginBottom: "50px" }}
            >
              Restaurant Pizzeria Grill - <b>Fabio</b>
            </p>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.iconSep}>
            <FontAwesomeIcon icon={faPhone} className={styles.icon} />
            <div className={styles.sep} />
          </div>
          <div className={styles.content}>
            <p className="title is-4 has-text-white">Téléphone</p>
            <p className="subtitle is-4 has-text-white-ter">03.84.43.21.78</p>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.iconSep}>
            <FontAwesomeIcon icon={faLocationArrow} className={styles.icon} />
            <div className={styles.sep} />
          </div>
          <div className={styles.content}>
            <p className="title is-4 has-text-white">Adresse</p>
            <p className="subtitle is-4 has-text-white-ter mb-0">
              2 Grande rue
            </p>
            <p className="subtitle is-4 has-text-white-ter">39210 Voiteur</p>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.iconSep}>
            <FontAwesomeIcon icon={faClock} className={styles.icon} />
            <div className={styles.sep} />
          </div>
          <div className={styles.content} style={{ marginBottom: "5px" }}>
            <p className="title is-4 has-text-white">Horaires</p>
            <p className="subtitle is-4 has-text-white-ter mb-0">
              Du mardi au samedi :
            </p>
            <p className="subtitle is-4 has-text-white-ter">
              12h/14h & 19h/22h
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Background path="/images/test.jpg">
      <>
        <Spacer />
        <div className={styles.container}>
          <Title isWhite>Où sommes-nous ?</Title>
          <div className={styles.wrapper}>
            <InfoDiv />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7731.876802623251!2d5.604441568051302!3d46.752452380951745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d266e2f7959ed%3A0x4a831039bc104b5a!2sFabio!5e0!3m2!1sen!2sfr!4v1673361551873!5m2!1sen!2sfr"
              loading="lazy"
              title="map"
              className={styles.mapContainer}
            ></iframe>
          </div>
        </div>
        <Spacer />
      </>
    </Background>
  );
}

export default Location;
