import styles from "./MainMenu.module.css";

import Title from "@/components/Title";
import data from "./main_menu";

function MainMenu() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <br/>
        <Title>A découvrir sur place</Title>
        <br/>
        <p className="subtitle is-4">Nos Salades</p>
        <div className={styles.menu_wrapper}>
          <table className="table">
            <tbody>
              {data.salades?.map((salade) => (
                <tr key={salade.name}>
                  <th>{salade.name}</th>
                  <th style={{ fontWeight: "400" }}>{salade.desc}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <p className="subtitle is-4">Les Viandes</p>
        <div className={styles.menu_wrapper}>
          <table className="table">
            <tbody>
              {data.viandes?.map((viande) => (
                <tr key={viande.name}>
                  <th>{viande.name}</th>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.infosContainer}>
            <p>
              Nos plats sont servis avec des Pommes de Terre Grenailles Roties
              au four et légumes de saison
            </p>
          </div>
        </div>
        <br />
        <br />
        <p className="subtitle is-4">Les Plats</p>
        <div className={styles.menu_wrapper}>
          <table className="table">
            <tbody>
              {data.plats?.map((plat) => (
                <tr key={plat.name}>
                  <th>{plat.name}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <p className="subtitle is-4">Nos Pizzas sur table</p>
        <div className={styles.menu_wrapper}>
          <table className="table">
            <tbody>
              {data.pizzas?.map((pizza) => (
                <tr key={pizza.name}>
                  <th>{pizza.name}</th>
                  <th style={{ fontWeight: "400" }}>{pizza.desc}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <p className="subtitle is-4">Nos Desserts & Glaces</p>
        <div className={styles.menu_wrapper}>
          <table className="table">
            <tbody>
              {data.desserts?.map((dessert) => (
                <tr key={dessert.name}>
                  <th>{dessert.name}</th>
                  <th style={{ fontWeight: "400" }}>{dessert.desc}</th>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.infosContainer}>
            <p>
              <b>Nos Parfums :</b> vanille, chocolat, poire, passion, café,
              cassis, framboise, fraise, menthe, citron, nougat, rhum-raisin,
              pistache, noix, noix de coco, nutella et caramel
            </p>
            <p>Supplément chantilly sur demande</p>
          </div>
        </div>
        <br/>
        <br />
      </div>
    </div>
  );
}

export default MainMenu;
