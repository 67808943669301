import styles from "./Menus.module.css";

import Title from "@/components/Title";

const menus = [
  {
    id: 1,
    img: "/images/gallery/preview/5.jpg",
    name: "Pizza",
    title: "A Emporter",
    content: "Profitez de notre cuisine maison, même chez vous",
    path: "/images/carte.pdf",
  },
  {
    id: 2,
    img: "/images/gallery/preview/3.jpg",
    path: "",
    name: "Salle",
    title: "Sur place",
    content: "Savourez notre cuisine familiale dans un cadre chaleureux",
  },
];

function Menus() {
  return (
    <div className={styles.container}>
      <Title>Notre Carte</Title>
      <br />
      <div className="tile is-ancestor">
        {menus.map((menu) => (
          <div className="tile is-parent" key={menu.id}>
            <div className="tile is-child">
              <div
                className="card"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={menu.img} alt={menu.name} />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">{menu.title}</p>
                      <p className="subtitle is-6">{menu.content}</p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="card-footer-item">
                    <a
                      href={menu.path || "/menu"}
                      target={menu.path ? "_blank" : ""}
                      rel="noreferrer"
                    >
                      <button
                        className="button is-link is-rounded"
                        style={{ cursor: "pointer", bottom: "0" }}
                      >
                        <span>Découvrir</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Menus;
