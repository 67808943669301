import styles from "./Gallery.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import Title from "../Title";

const items = [
  {
    img: "/images/gallery/preview/1.jpg",
    fullsize: "/images/gallery/fullsize/1.jpg",
    id: 0,
  },
  {
    img: "/images/gallery/preview/2.jpg",
    fullsize: "/images/gallery/fullsize/2.jpg",
    id: 1,
  },
  {
    img: "/images/gallery/preview/3.jpg",
    fullsize: "/images/gallery/fullsize/3.jpg",
    id: 2,
  },
  {
    img: "/images/gallery/preview/4.jpg",
    fullsize: "/images/gallery/fullsize/4.jpg",
    id: 3,
  },
  {
    img: "/images/gallery/preview/5.jpg",
    fullsize: "/images/gallery/fullsize/5.jpg",
    id: 4,
  },
  {
    img: "/images/gallery/preview/6.jpg",
    fullsize: "/images/gallery/fullsize/6.jpg",
    id: 5,
  },
  {
    img: "/images/gallery/preview/7.jpg",
    fullsize: "/images/gallery/fullsize/7.jpg",
    id: 6,
  },
  {
    img: "/images/gallery/preview/8.jpg",
    fullsize: "/images/gallery/fullsize/8.jpg",
    id: 7,
  },
  {
    img: "/images/gallery/preview/9.jpg",
    fullsize: "/images/gallery/fullsize/9.jpg",
    id: 8,
  },
];

function Gallery() {
  const [curIndex, setCurIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  function nextImage() {
    if (curIndex === items.length - 1) {
      setCurIndex(0);
    } else {
      setCurIndex(curIndex + 1);
    }
  }

  function prevImage() {
    if (curIndex === 0) {
      setCurIndex(items.length - 1);
    } else {
      setCurIndex(curIndex - 1);
    }
  }

  function closePopup() {
    setShowPopup(false);
  }

  function setCur(index: number) {
    setCurIndex(index);
    setShowPopup(true);
  }

  function ImagePopup({ img }: { img: string }) {
    return (
      <div className={styles.popup}>
        <div className={styles.popup_inner}>
          <button onClick={closePopup} className={styles.quit}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <img src={img} alt="Gallery" />
          <div className={styles.arrows}>
            <button onClick={prevImage}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button onClick={nextImage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.gallery}>
      <Title isWhite>Galerie</Title>
      <br />
      <div className={styles.container}>
        {items.map((item) => (
          <button onClick={() => setCur(item.id)} key={item.id}>
            <div className={styles.item} >
              <figure className="image is-4by3">
                <img src={item.img} alt="Gallery" />
              </figure>
            </div>
          </button>
        ))}
      </div>
      {showPopup && <ImagePopup img={items[curIndex].fullsize} />}
    </div>
  );
}

export default Gallery;
