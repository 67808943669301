import styles from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";

import Title from "@/components/Title";
import Background from "@/components/Background";

function Header() {
  return (
    <Background path="/images/background.jpg">
      <div className={styles.header}>
        <p className="subtitle is-4 has-text-white">
          Pizzeria - Grill - Restaurant
        </p>
        <div className={styles.container}>
          <div className={styles.content}>
            <Title isWhite>Fabio</Title>
            <p className="subtitle is-4 has-text-white mt-0">
              Laissez-vous tenter par notre carte, avec
              ses Menus, Pizzas, Salades repas, Grillades, et encore à découvrir
              !
            </p>
          </div>
          <a href="tel:+33384432178">
            <button
              className="button is-link is-rounded"
              style={{ cursor: "pointer" }}
            >
              <span>Nous appeler</span>
              <span className="icon">
                <FontAwesomeIcon icon={faPhoneFlip} />
              </span>
            </button>
          </a>
        </div>
      </div>
    </Background>
  );
}

export default Header;
