import styles from "./Background.module.css";

function Background({children, path}: {children: JSX.Element; path: string}) {
  return (
    <div className={styles.wrapper}>
      <img src={path} alt="Background" className={styles.img} />
      <div className={styles.loading}></div>
      {children}
    </div>
  );
}

export default Background;
