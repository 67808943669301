import MainMenu from "./components/MainMenu"
import "./styles/App.css"

function Menu() {
    return (
      <>
      <head>
        <title>Menu</title>
      </head>
      <MainMenu />
      <div className="returnContainer">
      <a href="/">
        <button
          className="button is-link is-rounded"
          style={{ cursor: "pointer" }}
        >
          <span>Retour</span>
        </button>
      </a>
      </div>
      </>
    )
}

export default Menu;
