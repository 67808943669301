import styles from "./About.module.css";

import Title from "@/components/Title";

function About() {
  return (
    <div className={styles.about}>
      <Title>A propos de nous</Title>
      <div className={styles.content}>
        <p className="subtitle is-5 has-text-black-bis">
          &nbsp;&nbsp;&nbsp;&nbsp;Le Restaurant - Pizzéria - Grill FABIO vous
          accueille à Voiteur entre vignoble et reculées sur le térritoire des
          Coteaux de la Haute Seille.
          <br />
          <br />
          Situé au centre du village, Toute l'équipe vous attends dans un cadre
          à la décoration chaleureuse, avec sa grande salle et ses deux
          terrasses.
          <br />
          <br />
          Découvrez notre carte variée, qui propose une multitude de plats
          savoureux pour satisfaire toutes vos envies culinaires, des pizzas aux
          burgers en passant par les salades repas et les grillades.
          <br />
          <br />
          Nous sommes ravis de vous accueillir sur notre nouveau site internet
          et espérons vous voir bientôt dans notre restaurant pour vous faire
          découvrir nos spécialités locales et notre cuisine maison.
        </p>
      </div>
    </div>
  );
}

export default About;
