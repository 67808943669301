import "@/styles/App.css";

import Header from "@/modules/Header";
import Menus from "@/modules/Menus";
import Location from "@/modules/Location";
import About from "./modules/About";
import Footer from "./modules/Footer";
import Spacer from "./components/Spacer";

function App() {
  return (
    <div className="App">
      <Header />
      <Spacer /> {/** Separator */}
      <Menus />
      <Spacer /> {/** Separator */}
      <Location />
      <Spacer /> {/** Separator */}
      <About />
      <Spacer /> {/** Separator */}
      <Footer />
    </div>
  );
}

export default App;
