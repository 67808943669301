function Spacer() {
  return (
    <>
      <br />
      <br />
    </>
  );
}

export default Spacer;
