import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "bulma/css/bulma.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Menu from "./Menu"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<App />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  </BrowserRouter>
);
